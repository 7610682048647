import './HalfImageHero.scss';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { constants } from '@ulta/core/utils/constants/constants';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import Link from '@ulta/modules/Link/Link';

import Tag from '@ulta/modules/Tag/Tag';

import { highlightString } from '@ulta/utils/Formatters/Formatters';

/**
 * Represents an HalfImageHero component
 * @method
 * @param {HalfImageHeroProps} props - React properties passed from composition
 * @returns HalfImageHero
 */

const HalfImageHero = ( props ) => {
  const { inflection } = useDeviceInflection();

  const halfImageHeroRef = useRef( null );
  const {
    rootMargin,
    root,
    threshold,
    backgroundColor,
    desktopImage,
    mobileImage,
    altText,
    tag,
    eyebrow,
    headlineHighlightColor,
    headlineTag,
    headline,
    headlineHighlight,
    subheadline,
    linkAction,
    linkAction2,
    buttonAction,
    buttonAction2
  } = props;
  const hasLinkAction1 = linkAction?.label && !linkAction2?.label && !buttonAction?.label && !buttonAction2?.label;
  const hasLinkAction2 = linkAction2?.label && !linkAction?.label && !buttonAction?.label && !buttonAction2?.label;
  const hasButtonLinkAction = buttonAction?.label && linkAction?.label && !buttonAction2?.label && !linkAction2?.label;
  const hasButtonLinkAction2 = buttonAction?.label && linkAction2?.label && !buttonAction2?.label && !linkAction?.label;
  const hasButton2LinkAction = buttonAction2?.label && linkAction?.label && !buttonAction?.label && !linkAction2?.label;
  const hasButton2LinkAction2 = buttonAction2?.label && linkAction2?.label && !buttonAction?.label && !linkAction?.label;

  useIntersectionObserver( halfImageHeroRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  if( !desktopImage || !mobileImage || !headline ){
    return null;
  }

  const buttonLinkFormatter  = ( buttonAction, linkAction ) => {
    return (
      <div className='HalfImageHero__buttonLink'>
        <div className='HalfImageHero__buttonLink--label'>
          <Button
            compact
            primary
            label={ buttonAction.label }
            withHover
            ariaLabel={ buttonAction.label + ' ' + headline }
            action={ buttonAction }
          />
        </div>
        <div className='HalfImageHero__actionsLink--label'>
          <Link_Huge
            target='_self'
            withHover
            ariaLabel={ linkAction.label + ' ' + headline }
            action={ linkAction }
          >
            { linkAction.label }
          </Link_Huge>
        </div>
      </div>
    );
  };

  return (
    <div className={
      classNames( 'HalfImageHero', {
        [`HalfImageHero__backgroundColor--${backgroundColor}`]: backgroundColor
      } )
    }
    ref={ halfImageHeroRef }
    >
      <GridContainer>
        <div className='HalfImageHero__leftcolumn'>
          { tag &&
            <div className='HalfImageHero__leftcolumnTag'>
              <Tag
                style='default'
                message={ tag }
              />
            </div>
          }
          { eyebrow &&
            <div className='HalfImageHero__leftcolumnEyebrow'>
              <Text
                htmlTag='span'
                textStyle='eyebrow'
              >
                { eyebrow }
              </Text>
            </div>
          }
          <div className={ classNames( 'HalfImageHero__leftcolumnHeadline',
            ( headlineHighlightColor && `HalfImageHero__headlineHighlightColor--${headlineHighlightColor}` ), {
            } ) }
          >
            <Text
              htmlTag={ headlineTag }
              textStyle='title-2'
            >
              { highlightString( headline, headlineHighlight ) }
            </Text>
          </div>
          { subheadline &&
            <div className='HalfImageHero__leftcolumnSubheadline'>
              <Text
                htmlTag='p'
                textStyle='subtitle-1'
              >
                { subheadline }
              </Text>
            </div>
          }
          { hasLinkAction1 &&
            <div className='HalfImageHero__actionsArrowLink'>
              <Link
                linkAction={ linkAction }
                linkIcon='ArrowForward'
                linkStyle='title-6'
                ariaLabel={ linkAction.label + ' ' + headline }
              />
            </div>
          }
          { hasLinkAction2 &&
            <div className='HalfImageHero__actionsArrowLink'>
              <Link
                linkAction={ linkAction2 }
                linkIcon='ArrowForward'
                linkStyle='title-6'
                ariaLabel={ linkAction2.label + ' ' + headline }
              />
            </div>
          }
          { linkAction?.label && linkAction2?.label &&
            <div className='HalfImageHero__actionsLink'>
              { linkAction?.label &&
                <div className='HalfImageHero__actionsLink--label'>
                  <Link_Huge
                    target='_self'
                    withHover
                    ariaLabel={ linkAction.label + ' ' + headline }
                    action={ linkAction }
                  >
                    { linkAction.label }
                  </Link_Huge>
                </div>
              }
              { linkAction2 && linkAction2?.label &&
                <div className='HalfImageHero__actionsLink--label'>
                  <Link_Huge
                    target='_self'
                    withHover
                    ariaLabel={ linkAction2.label + ' ' + headline }
                    action={ linkAction2 }
                  >
                    { linkAction2.label }
                  </Link_Huge>
                </div>
              }
            </div>
          }
          { buttonAction?.label && buttonAction2?.label &&
            <div className='HalfImageHero__buttonLink'>
              <div className='HalfImageHero__buttonLink--label'>
                <Button
                  compact
                  primary
                  label={ buttonAction.label }
                  ariaLabel={ buttonAction.label + ' ' + headline }
                  action={ buttonAction }
                  withHover
                />
              </div>
              <div className='HalfImageHero__buttonLink--label'>
                <Button
                  compact
                  secondary
                  label={ buttonAction2.label }
                  ariaLabel={ buttonAction2.label + ' ' + headline }
                  action={ buttonAction2 }
                  withHover
                />
              </div>
            </div>
          }
          { hasButtonLinkAction &&
              buttonLinkFormatter( buttonAction, linkAction )
          }
          { hasButtonLinkAction2 &&
              buttonLinkFormatter( buttonAction, linkAction2 )
          }
          { hasButton2LinkAction &&
              buttonLinkFormatter( buttonAction2, linkAction )
          }
          { hasButton2LinkAction2 &&
              buttonLinkFormatter( buttonAction2, linkAction2 )
          }
        </div>
        <div className='HalfImageHero__rightcolumn'>
          { desktopImage &&
            <UltaAsset
              { ...( inflection.MOBILE ? mobileImage : desktopImage ) }
              bgAnimation = { true }
            />
          }
        </div>
      </GridContainer>
    </div>
  );
};

/**
  * Property type definitions
  * @typedef HalfImageHeroProps
  * @type {object}
  * @property {string} desktopImage - Sets the desktop image
  * @property {string} mobileImage - Sets the mobile image
  * @property {string} tag - Sets the tag
  * @property {string} eyebrow - Sets the eyebrow text
  * @property {string} headline - Sets the headline text
  * @property {string} headlineTag - Sets the headlineTag text
  * @property {string} headlineHighlight - Sets the headlineHighlight
  * @property {string} headlineHighlightColor - Sets the healineHighlightColor
  * @property {string} subheadline - Sets the subheadline text
  * @property {object} linkAction - Sets the linkAction
  * @property {object} linkAction2 - Sets the linkAction2
  * @property {object} buttonAction - Sets the buttonAction
  * @property {object} buttonAction2 - Sets the buttonAction2
  * @property {string} backgroundColor - Sets the backgroundColor
  */

export const propTypes = {
  /** Sets the desktopImage for display */
  desktopImage: PropTypes.object,
  /** Sets the mobileImage for display */
  mobileImage: PropTypes.object,
  /** Sets the tag for display */
  tag: PropTypes.string,
  /** Sets the eyebrow for display */
  eyebrow: PropTypes.string,
  /** Sets the headline for display */
  headline: PropTypes.string,
  /** Sets the headlineTag for display */
  headlineTag: PropTypes.string,
  /** Sets the headlineHighlight for display */
  headlineHighlight: PropTypes.string,
  /** Sets the headlineHighlightColor for display */
  headlineHighlightColor: PropTypes.string,
  /** Sets the subheadline for display */
  subheadline: PropTypes.string,
  /** Sets the linkAction for display */
  linkAction: PropTypes.object,
  /** Sets the linkAction2 for display */
  linkAction2: PropTypes.object,
  /** Sets the buttonAction for display */
  buttonAction: PropTypes.object,
  /** Sets the buttonAction2 for display */
  buttonAction2: PropTypes.object,
  /** Sets the background color */
  backgroundColor: PropTypes.string
};

/**
 * Default values for passed properties
 * @type object
 * @property {string} headlineTag - Default headlineTag is h2
 */
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS,
  headlineTag: 'h2'
};

HalfImageHero.defaultProps = defaultProps;
HalfImageHero.propTypes = propTypes;

export default HalfImageHero;